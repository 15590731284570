import classNames from 'classnames';
import {Link, StaticQuery, graphql} from 'gatsby';
import React from 'react';
import {Helmet} from 'react-helmet';

import NavigationLinks from './navigation_links';
import '../styles/main.css';

class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  toggleMenu = evt => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  renderMenuButtonIcon() {
    if (this.state.menuOpen) {
      return (
        <svg width='35' height='45'>
          <line x1='10' y1='14' x2='32' y2='31' strokeWidth='2' stroke='#888' strokeLinecap='round' />
          <line x1='10' y1='31' x2='32' y2='14' strokeWidth='2' stroke='#888' strokeLinecap='round' />
        </svg>
      );
    } else {
      return (
        <svg width='35' height='45'>
          <line x1='10' y1='14' x2='32' y2='14' strokeWidth='2' stroke='#888' strokeLinecap='round' />
          <line x1='10' y1='22.5' x2='32' y2='22.5' strokeWidth='2' stroke='#888' strokeLinecap='round' />
          <line x1='10' y1='31' x2='32' y2='31' strokeWidth='2' stroke='#888' strokeLinecap='round' />
        </svg>
      );
    }
  }

  homepageFields() {
    return this.props.homepageNode.pagesYaml[`fields_${this.props.language}`];
  }

  homepageTitle() {
    return this.homepageFields().nav_title || this.homepageFields().title;
  }

  nodeFields() {
    const fields = this.props.nodeData && this.props.nodeData[`fields_${this.props.language}`];
    return fields || {};
  }

  langAlternates() {
    const node_data = this.props.nodeData;
    if (!node_data) {
      return null;
    }
    return {sl: node_data.fields_sl.slug, en: node_data.fields_en.slug};
  }

  langAlternate(lang) {
    const alternates = this.langAlternates() || {sl: '/', en: '/en'};
    return alternates[lang];
  }

  metaDescriptionTag() {
    const fields = this.nodeFields();
    if (fields.meta_description) {
      return <meta name='description' content={fields.meta_description}/>;
    }
    return null;
  }

  metaKeywordsTag() {
    const fields = this.nodeFields();
    if (fields.meta_keywords) {
      return <meta name='keywords' content={fields.meta_keywords}/>;
    }
    return null;
  }

  jsonSchema(schemas) {
    return schemas.map(schema => <script type="application/ld+json">{schema}</script>);
  }

  renderLangAlternate(lang) {
    const alternates = this.langAlternates();
    if (!alternates) {
      return null;
    }
    return (
      <link rel='alternate' href={alternates[lang]} hreflang={lang}/>
    );
  }

  renderPrivacyPolicyLink(lang) {
    const title = this.props.language === 'en' ? 'Privacy Policy' : 'Politika zasebnosti';
    const slug = this.props.language === 'en' ? '/privacy-policy' : '/politika-zasebnosti';
    return (
      <Link to={slug}>{title}</Link>
    );
  }

  render() {
    return (
      <div className={classNames('layout', this.props.className)}>
        <Helmet>
          <link rel='icon' type='image/png' href='/favicon.png'/>
          {this.renderLangAlternate('sl')}
          {this.renderLangAlternate('en')}
          <html lang={this.props.language}/>
          {this.metaDescriptionTag()}
          {this.metaKeywordsTag()}
          {this.jsonSchema(json_schemas)}
          <meta property='og:image'
                content='https://www.andrazgregoric.com/images/porocni-fotograf-porocna-fotografija.jpg'/>
        </Helmet>
        <header>
          <button className='menu-icon' onClick={this.toggleMenu}>
            {this.renderMenuButtonIcon()}
          </button>
          <nav data-open={this.state.menuOpen}>
            <NavigationLinks language={this.props.language}/>
            <div className='language-selector'>
              <Link to={this.langAlternate('sl')} title='Slovenščina'>sl</Link>
              <Link to={this.langAlternate('en')} title='English'>en</Link>
            </div>
          </nav>
          <Link className='logo' to={this.homepageFields().slug} title={this.homepageTitle()}>
            <img alt='Logo' src='/images/logo.svg'/>
            <span className='title'>Andraž Gregorič</span>
            <span className='subtitle'>
              {this.props.language === 'en' ? 'Professional Photography' : 'Fotografske storitve'}
            </span>
          </Link>
        </header>
        <main className={classNames({homepage: this.nodeFields().slug === this.homepageFields().slug})}>
          {this.props.children}
        </main>
        <footer>
          <div className='social-links'>
            <span>Follow Me</span>
            <a href='https://www.facebook.com/AndrazGregoricPhotography' target='_blank' rel='noopener noreferrer'>
              <img src='/images/social/facebook.svg' alt='Facebook'/>
            </a>
            <a href='https://www.instagram.com/andrazgregoric/' target='_blank' rel='noopener noreferrer'>
              <img src='/images/social/instagram.svg' alt='Instagram'/>
            </a>
          </div>
          <button className='back-to-top' onClick={evt => window.scrollTo(0, 0)}>
            {this.props.language === 'sl' ? 'Nazaj na vrh ↑' : 'Back to top ↑'}
          </button>
          <div className='copyright'>
            <p>
              {this.renderPrivacyPolicyLink(this.props.language)}
            </p>
            <p>
              Andraž Gregorič {new Date().getFullYear()} |
              Destination wedding photographer
            </p>
            <p>
              Slovenia | Worldwide
            </p>
          </div>
        </footer>
      </div>
    );
  }

}

const json_schemas = [
  `
    {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "url": "https://www.andrazgregoric.com/",
      "image": "https://www.andrazgregoric.com/images/logo.svg",
      "name":"Andraž Gregorič - Fotografske storitve",
      "description": "Poročno fotografiranje - profesionalno fotografiranje porok,parov, družin, nosečnic in novorojenčkov. Ponujamo vrhunske poročne fotografije.",
      "address":
        {
          "@type":"PostalAddress",
          "streetAddress":"Preloška cesta 3",
          "addressLocality":"Domžale",
          "addressCountry":"Slovenia",
          "postalCode":"1230"
      },
      "hasMap": "https://www.google.com/maps/place/Andra%C5%BE+Gregori%C4%8D+%7C+Poro%C4%8Dni+fotograf+%7C+Poro%C4%8Dna+fotografija+%7C+Fotografske+storitve/@46.1230555,14.6133628,15z/data=!4m2!3m1!1s0x0:0x777d6bb901f967d9?sa=X&ved=2ahUKEwip0syH6cfsAhVFwzgGHe4DAXcQ_BIwT3oECD8QBQ",
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "opens": "07:00",
          "closes": "21:00"
        }
      ],
      "telephone": "+38631504434",
      "email": "info@andrazgregoric.com",
      "priceRange": "$$",
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "46.1230555",
        "longitude": "14.6133628"
      }
    }
  }`,
  `
    {
      "@context":"https://schema.org",
      "@type":"WebSite",
      "@id":"#website",
      "url":"https://www.andrazgregoric.com/",
      "name":"Andraž Gregorič - Fotografske storitve",
      "potentialAction": {
        "@type":"SearchAction",
        "target":"https://www.andrazgregoric.com/search?q={search_term_string}",
        "query-input":"required name=search_term_string"
      }
    }
  `,
  `
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      "url": "https://www.andrazgregoric.com/",
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "telephone": "+38631504434",
          "contactType": "customer service"
        }
      ]
    }
  `,
  `
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      "url": "https://www.andrazgregoric.com/",
      "logo": "https://www.andrazgregoric.com/images/logo.svg"
    }
  `,
  `
    {
      "@context": "https://schema.org/",
      "@type": "CreativeWorkSeries",
      "name": "Andraž Gregorič - Fotografske storitve",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "ratingCount": "5"
      }
    }
  `,
  `
    {
      "@context": "https://schema.org",
      "@type": "Person",
      "name": "Andraž Gregorič",
      "url": "https://www.andrazgregoric.com/",
      "sameAs": [
        "https://www.facebook.com/AndrazGregoricPhotography/",
        "https://www.instagram.com/andrazgregoric/",
        "https://twitter.com/AndrazGregoric"
      ]
    }
  `,
  `
    {
      "@context": "https://schema.org/",
      "@type": "SiteNavigationElement",
      "headline": "Andraž Gregorič - Fotografske storitve",
      "name": [
        "Home",
        "Ponudba",
        "Blog",
        "Kontakt",
        "O meni",
        "Pogosta vprašanja"
      ],
      "description": [
        "Poročno fotografiranje - profesionalno fotografiranje porok,parov, družin, nosečnic in novorojenčkov. Ponujamo vrhunske poročne fotografije.",
        "Cenik fotografiranja porok, parov, družin, nosečnic in novorojenčkov. Poročni fotograf cena. Cenik fotografiranja prireditev in dogodkov.",
        "Blog - zapisi in objave aktualnih fotografskih zgodb. Profesionalni fotograf Andraž Gregorič, ki ponuja fotografiranje za vse priložnosti.",
        "Profesionalni fotograf, inženir fotografije Andraž Gregorič. Profesionalno fotografiranje porok. Lifestyle družinska in poročna fotografija",
        "Poročni fotograf 2021, 2022 – profesionalni fotograf, ki se s poročno fotografijo ukvarja že 15 let. Slovenski poročni fotograf.",
        "Fotografiranje poroke - odgovori na najbolj pogosta vprašanja o fotografiranju poroke in kako poteka najem poročnega fotografa."
      ],
      "url": [
        "https://www.andrazgregoric.com/",
        "https://www.andrazgregoric.com/cenik-fotografiranja/",
        "https://www.andrazgregoric.com/blog/",
        "https://www.andrazgregoric.com/kreativni-porocni-fotograf/",
        "https://www.andrazgregoric.com/porocni-fotograf/",
        "https://www.andrazgregoric.com/fotografiranje-poroke/"
      ]
    }
  `
];

export default props => (
  <StaticQuery
    query={graphql`
      {
        pagesYaml(fields_sl: {slug: {eq: "/"}}) {
          fields_sl {
            slug
            title
            nav_title
          }
          fields_en {
            slug
            title
            nav_title
          }
        }
      }
    `}
    render={data => <Layout homepageNode={data} {...props} />}
  />
);
